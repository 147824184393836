/* @import "react-slideshow-image/dist/styles.css"; */

.your-app {
    .rsis-container {
        background-size: contain !important;
    }
  }
  
  .your-app {
    .rsis-image {
      background-size: contain !important;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .err-redirection:hover{
    color: teal;
    
  }

input[type="file"] {
  display: none;
}
  
.custom-file-upload {
  outline: 2px dashed gray;
  display: inline-block;
  padding: 40px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}
